/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@angular/cdk/overlay-prebuilt.css';
@import "~@ng-select/ng-select/themes/material.theme.css";

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/MaterialIcons-Regular.woff2); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(./assets/fonts/MaterialIcons-Regular.woff2) format('woff2');
  font-display: swap;
}
/*__________________globals____________________*/
html, body { height: 100%; }
.success-snackbar {
  background-color: #36ad6f !important;
  color: #ffffff;
  text-align: center;
}
.material-icons {
  font-family: 'Material Icons', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
.error-snackbar {
  background-color: #dc3545 !important;
  color: #ffffff;
}
.error-snackbar .mat-simple-snackbar {
  position: relative;
}
.mat-icon {
  font-size:24px!important;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mat-accent .mat-slider-thumb {
  background-color: rgb(0, 87, 159) !important;
}
.mat-accent .mat-slider-track-fill {
  background-color: rgb(0, 87, 159) !important;
}
.mat-accent .mat-slider-thumb-label {
  background-color: rgb(0, 87, 159) !important;
}
.mat-slider {
  padding: 0 8px !important;
}
/*_______________________________________*/

/*________________colors_______________*/
.primary-text-col {
  color: rgb(0, 87, 159) !important;
}
.primary-bg {
  background-color: rgb(0, 87, 159) !important;
}
.main-text-color {
  color: rgb(92,92,92) !important;
}
.error-col {
  color: rgb(244,67,54) !important;
}
.error-col-bg {
  background-color: rgb(244,67,54);
}
.send-color {
  color: rgb(0, 87, 159);
}
/*_____________________________________*/
.card-content .mat-radio-outer-circle {
  border-color: #00579f !important;
}
.card-content .mat-radio-button .mat-radio-inner-circle {
  background-color: #00579f !important;
}

/*__________________auth___________________*/
.lang .mat-select-value-text {
  color: #00579f !important;
}
.auth-main {
  height: 100vh;
  overflow: hidden;
}

.mat-checkbox-label {
  vertical-align: middle !important;
}
/*_____________________________________*/

/*_______________navBar________________*/
.space:after {
  position: absolute;
  content: '';
  width: 1px;
  top: 25%;
  bottom: 0;
  height: 25px;
  background-color: #00579f !important;
}
/*_____________________________________*/

/*_______________card-tab______________*/

.card-content .card-slider-header:after {
  position: absolute;
  content: '';
  transform: translateY(12px);
  height: 1px;
  width: 100%;
  z-index: -1;
  background-color: #c1c1c1;
}
.card-content .card-slider-header-corr:after {
  position: absolute;
  content: '';
  transform: translateY(12px);
  height: 1px;
  width: 100%;
  z-index: -1;
  background-color: #c1c1c1;
}
.card-content .card-slider:nth-child(5) .card-slider-header:after {
  width: 0 !important;
}

.card-content .card-slider-corr:nth-child(2) .card-slider-header-corr:after {
  width: 0 !important;
}
/*______________________________________________________*/


/*_______________________perfect-scrollbar______________________________________*/

.card-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.card-content::-webkit-scrollbar-track {
  background: rgba(150, 150, 150, .1);
  border-radius: 5px;
  transition: .1.7s;
}
.card-content::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, .3);
  border-radius: 5px;
  transition: .1.7s;
}
#grid-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
#grid-content::-webkit-scrollbar-track {
  background: rgba(150, 150, 150, .1);
  border-radius: 5px;
  transition: .1.7s;
}
#grid-content::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, .3);
  border-radius: 5px;
  transition: .1.7s;
}
.mat-select-panel::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
.mat-select-panel::-webkit-scrollbar-track {
  background: rgba(150, 150, 150, .1);
  border-radius: 5px;
  transition: .1.7s;
}
.mat-select-panel::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, .3);
  border-radius: 5px;
  transition: .1.7s;
}
.mainContent {
  overflow: auto;
}
.mainContent::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.mainContent::-webkit-scrollbar-track {
  background: rgba(150, 150, 150, .1);
  border-radius: 5px;
  transition: .1.7s;
}
.mainContent::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, .3);
  border-radius: 5px;
  transition: .1.7s;
}
.wp-list-content .mat-drawer-inner-container::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
.wp-list-content .mat-drawer-inner-container::-webkit-scrollbar-track {
  background: rgba(150, 150, 150, .1);
  border-radius: 5px;
  transition: .1.7s;
}
.wp-list-content .mat-drawer-inner-container::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, .3);
  border-radius: 5px;
  transition: .1.7s;
}

.dl-search-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.dl-search-container::-webkit-scrollbar-track {
  background: rgba(150, 150, 150, .1);
  border-radius: 5px;
  transition: .1.7s;
}
.dl-search-container::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, .3);
  border-radius: 5px;
  transition: .1.7s;
}
/*______________________________________________________________________________*/

.full-info .right .mat-radio-label-content {
  float: right;
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00579f;
}
.active-title {
  background-color: rgb(0, 87, 159) !important;
}
/*__________________________order______________________________*/
.order-mat-tab .mat-tab-label {
  min-width: 340px;
}
.order-comment .mat-action-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.order-comment .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.order-comment .mat-expansion-panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  border-radius: inherit;
}
.order-comment .mat-content {
  position: absolute;
  left: 40px;
  top: 6px;
}
.order-modal .mat-form-field-infix {
  border-top: 0;
}
.order-modal .mat-form-field{
  font-size: 12px;
}
.order-modal .mat-radio-outer-circle {
  border-color: #00579f !important;
}
.order-modal .mat-radio-inner-circle {
  background-color: #00579f !important;
}
.order-modal .mat-checkbox-ripple .mat-ripple-element,.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #00579f !important;}
/*______________________________________________________________*/

.custom-mat-icon .mat-icon {
  font-size: 18px!important;
}

.teeth-chip .mat-chip{
  background-color: #F16784 !important;
  color: white !important;
  border-radius: 5px !important;
  font-size: 18px;
}
.teeth-chip .mat-chip .mat-icon{
  background-color: #F16784  !important;
  color: white !important;
  font-size: 18px;
}

.teeth-chip .mat-icon {
  font-size: 20px!important;
}

.teeth-chip .mat-standard-chip {
  transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
  display: inline-flex;
  padding: 7px 12px;
  border-radius: 0px;
  align-items: center;
  cursor: default;
  min-height: 26px;
  height: 1px;
}


.order-details .mat-tab-label {
  min-width: 775px;
}
button:focus {
  outline: none !important;
}
.grid-paginator {
  font-size: 12px;
  max-height: 23px;
  opacity: .9;
  color: rgb(92,92,92) !important;
}

.filter .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0 !important;
}
.filter .mat-form-field-appearance-legacy .mat-form-field-ripple {
  height: 0 !important;
}
.filter .mat-form-field-label {
  display: block !important;
  overflow: visible !important;
}
.filter .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(0) scale(1) perspective(0) translateZ(0) !important;
}
.filter .mat-select-value span {
  display: none !important;
}
.z-0 {
  z-index: 0 !important;
}

input:focus {
  outline: none !important;
}
mat-icon:focus {
  outline: none !important;
}

.activeLink {
  font-weight: bold;
}
.work-panel-list:nth-child(1) {
  padding-top: 30px;
}
.cursor-hand {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.mat-select-panel {
  /*position: relative;*/
  /*top: 39px;*/
  /*font-size: 12px !important;*/
}
.mat-checkbox-layout {
  width: 100%;
  padding-left: 20px;
  display: block !important;
}
.mat-tab-label {
  width: 100%;
}

/*.mat-option {*/
/*  padding: 0 !important;*/
/*}*/
.over-auto {
  overflow: auto !important;
}
.over-hidden {
  overflow: hidden !important;
}

.page-print {
  margin: 0 !important;
  width: auto !important;
}

.page-print * {
  font-size: 14px !important;
}

.doctor_option .mat-option-text {
  padding-left: 15px;
}

.grid-header {
  background-color: rgb(250,250,250);
  display: block;
  text-align: center;
}

.grid-header-width {
  width: 160px;
}

.order-next-date {
  position: relative;
  top: 20px;
}

.active-row {
  background-color: rgb(240,240,240);
}
mat-option {
  padding-left: 10px !important;
}

.overflow-x {
  overflow-x: hidden !important;
}

.fade-in, .btn-mini {
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.5s; /* Firefox < 16 */
  -ms-animation: fadein 1.5s; /* Internet Explorer */
  -o-animation: fadein 1.5s; /* Opera < 12.1 */
  animation: fadein 1.5s;
  transition-timing-function: ease-in;
}

.fade-out {
  -webkit-animation: fadeout 0.7s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 0.7s; /* Firefox < 16 */
  -ms-animation: fadeout 0.7s; /* Internet Explorer */
  -o-animation: fadeout 0.7s; /* Opera < 12.1 */
  animation: fadeout 0.7s;
  transition-timing-function: ease-out;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@media only screen and (max-width: 2156px) {
  .image-margin {
    margin-top: 23px !important;
  }
}

@media only screen and (max-width: 1536px) {
  .image-margin {
    margin-top: -14px !important;
  }
}

@media only screen and (max-width: 2156px) {
  .image-margin-reg {
    /*margin-top: 83px !important;*/
  }
}

@media only screen and (max-width: 1536px) {
  .image-margin-reg {
    /*margin-top: 46px !important;*/
  }
}

.custom-slider .ngx-slider .ngx-slider-bar {
  background: #DEDEDE;
  height: 5px;
}
.custom-slider .ngx-slider .ngx-slider-selection {
  background: #869CD6;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  width: 8px;
  height: 16px;
  top: auto; /* to remove the default positioning */
  bottom: 0;
  background-color: #869CD6;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider .ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}

.custom-slider .ngx-slider .ngx-slider-limit {
  font-weight: bold;
  color: #333;
}
.custom-slider .ngx-slider-inner-tooltip {
  /*background-color: #333;*/
}

.custom-slider .ngx-slider .ngx-slider-tick {
  width: 2px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: #333;
  top: -1px;
}

.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: orange;
}

#new-slider span.ticks:not(:nth-of-type(9)):after {
  content: '';
  position: absolute;
  left: 50%;
  top: -13px;
  width: .05em;
  height: 16px;
  z-index: 2;
  background-color: #777;
}
#new-slider span.ticks:nth-of-type(6):after {
  content: '';
  position: absolute;
  left: 0;
  top: -13px;
  width: .05em;
  height: 16px;
  z-index: 2;
  background-color: #777;
}
.slider-bar-selection{
  position: absolute;
  top: 0;
  z-index: 1;
}









